<template>
    <vFormPage>
        <div slot="header" class="header">
            <img :src="SIGN_SRC" />
        </div>
        <div class="signin-body">
            <div class="form-title-wrapper">
                <div class="title">Welcome</div>
            </div>
            <vForm class="form" ref="form" :model="formInfo" :rules="rules">
                <vFormItem label="Phone" prop="phoneNum">
                    <div class="flex">
                        <input ref="phone" v-model="formInfo.phonePrefix" />
                        <vFormInput type="number" v-model.number="formInfo.phoneNum" />
                    </div>
                </vFormItem>
                <vFormItem label="Verify Code" prop="phoneCode" class="code-wrapper">
                    <div class="flex">
                        <vFormInput class="code-input" v-model.trim="formInfo.phoneCode" />
                        <vButton :text="codeText" type="primary-o-bold" :code="codeType" :clickBefore="verifyBefore"
                            @btnClick="getCode" :stopTimer="stopTimer" />
                    </div>
                </vFormItem>
                <div class="footer-info">
                    Please enter correct number, we will notify you via text message
                </div>
                <div class="btn-wrapper">
                    <vButton :text="is_loading ? 'Joining ...' : 'Join'" @btnClick="signin" />
                </div>
            </vForm>
        </div>
        <div slot="body-footer">
            <div class="footer-info">
                By joining in, you agree to Minitable Tech, Inc
            </div>
            <div class="footer-info">
                <a href="http://info.minitable.net/termsofuse.html" target="_blank">Terms of Service</a>
                and
                <a href="http://info.minitable.net/privacypolicy.html" target="_blank">Privacy Policy</a>
            </div>
        </div>
    </vFormPage>
</template>
<style scoped>
    /* 顶部样式 */
    .header img {
        transform: scale(.5);
    }

    /* 底部样式 */
    .footer-info {
        text-align: center;
        font-size: 13px;
        color: #AAB0BB;
        margin-bottom: 11px;
    }

    .footer-info:nth-child(2) {
        padding-top: 12px;
    }

    .footer-info a {
        color: #0080ff;
    }

    /* 表单样式 */
    .signin-body .footer-info {
        font-size: 10px;
        text-align: left;
        margin-bottom: 10px;
    }

    .signin-body .form {
        margin: 100px 20px 61px 20px;
    }

    .signin-body .form .flex {
        display: flex;
    }

    .signin-body .form-item {
        font-size: 14px;
    }

    .signin-body .form-title-wrapper {
        margin: 39px 31px 34px 31px;
        text-align: center;
        line-height: 18px;
    }

    .signin-body .form-title-wrapper .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
    }

    .signin-body .code-wrapper {
        margin-bottom: 0;
    }

    .signin-body .code-input {
        width: 60%;
    }

    .btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .btn-wrapper .btn {
        width: 166px;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { SIGN_SRC, PASS_SRC } from '../../consts/const.js';
    import { isExistUser } from '../../kits/tools.js';
    import vFormPage from '../../components/Formpage.vue';
    import vButton from '../../components/Button.vue';
    import vForm from '../../components/form/Form.vue';
    import vFormItem from '../../components/form/FormItem.vue';
    import vFormInput from '../../components/form/FormInput.vue';
    import intlTelInput from 'intl-tel-input';

    export default {
        name: 'v-sign-in',
        components: { vFormPage, vButton, vForm, vFormItem, vFormInput },
        data() {
            return {
                SIGN_SRC, PASS_SRC,
                codeText: 'Get Code',
                codeType: true,
                phoneOptions: ['+1', '+86'],
                formInfo: {
                    phonePrefix: "+1",
                    phoneNum: "",
                    phoneCode: ""
                },
                rules: {
                    phonePrefix: [{ required: true, trigger: 'blur' }],
                    phoneNum: [{ required: true, trigger: 'blur' }],
                    phoneCode: [{ required: true, trigger: 'blur' }],
                },
                stopTimer: false,
                iti: undefined,
                invite_by: this.$route.query.invite_by || ''
            }
        },
        created() {
        },
        mounted() {
            this.iti = intlTelInput(this.$refs.phone, {
                separateDialCode: true,
                initialCountry: 'us'
                // onlyCountries: [{

                // }, {}]
            });
        },
        computed: {
            ...mapGetters({
                is_loading: 'app/getLoading',
                sInfo: 'app/getsInfo',
                user: 'app/getUser',
            })
        },
        methods: {
            ...mapActions({
                triggerSms: 'sms/triggerSms',
                verifySms: 'sms/verifySms',
                submitUser: 'app/submitUser',
                checkActive: 'app/checkActive',
                verifyUser: 'app/verifyUser'
            }),
            ...mapMutations({
                updateUser: 'app/SET_USER',
                setLoading: 'app/APP_SET_IF_LOADING',
                showMsg: 'app/APP_SET_MSG'
            }),
            setPhonePrefix() {
                const selected = this.iti.getSelectedCountryData();
                this.formInfo.phonePrefix = `+${selected.dialCode}`;
            },
            signin() {
                this.setPhonePrefix();
                this.$refs.form.validate((valid) => {
                    if (!valid || this.is_loading) {
                        return;
                    }
                    this.setLoading({ is_loading: true });
                    let mobile = `${this.formInfo.phonePrefix}${this.formInfo.phoneNum}`;
                    this.verifySms({
                        data: { mobile, code: this.formInfo.phoneCode },
                        success: (resData) => {
                            this.stopTimer = true;
                            if (!resData.isValid) {
                                this.setLoading({ is_loading: false });
                                return;
                            }
                            this.verifyUser({
                                data: {},
                                success: (submitRes) => {
                                    if (!submitRes || !submitRes.cid) { // 注册成功，但是无正确注册信息返回
                                        return;
                                    }
                                    if (!this.$route.params.sid) { // url没有传sid，则跳过checkactive流程
                                        this.updateUser({ home: true });
                                        this.$router.replace('/pass');
                                        return;
                                    }
                                    this.checkActive({
                                        data: { sid: submitRes.sid, cid: submitRes.cid },
                                        success: (check) => {
                                            this.setLoading({ is_loading: false });
                                            if (check.active) {
                                                this.updateUser({ home: true });
                                                this.$router.replace({
                                                    name: '/pass/detail',
                                                    query: { sid: submitRes.sid, cid: submitRes.cid },
                                                });
                                            } else {
                                                // this.$router.replace('/pass/active');
                                                this.$router.replace({
                                                    name: '/pass/active',
                                                    query: { sid: submitRes.sid, cid: submitRes.cid, invite_by: this.invite_by },
                                                });
                                            }
                                        },
                                        fail: () => {
                                            this.setLoading({ is_loading: false });
                                        }
                                    });
                                },
                                fail: () => {
                                    this.setLoading({ is_loading: false });
                                }
                            });
                        }, fail: () => {
                            this.setLoading({ is_loading: false });
                        }
                    });
                });
            },
            verifyBefore() {
                return this.formInfo && this.formInfo.phoneNum !== "";
            },
            getCode() {
                this.triggerSms({ data: { mobile: `${this.formInfo.phonePrefix}${this.formInfo.phoneNum}` } });
            }
        }
    };
</script>